import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/layout'
import CrossroadsA from '../components/crossroads-a'
import SEO from "../components/seo"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

export default props => {
    const intl = useIntl()

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: "workshops.title" })}
                description={intl.formatMessage({ id: "workshops.text" })}
            />
            <div className="header-bg header-bg-01">
                <div className="tip-container">
                    <div className="tip"><FormattedMessage id="site.clickHold" /></div>
                </div>
            </div>
            <div className="container mx-auto py-16 md:py-24 px-8">
                <div className="mb-16">
                    <p className="typo-xxl mb-4 md:mb-8"><FormattedMessage id="workshops.title" /></p>
                    <div className="xl:w-3/4">
                        <p className="typo-text-xxl mb-8">
                            <FormattedMessage id="workshops.text" />
                        </p>
                    </div>
                </div>
                <div className="">
                    <div className="xl:w-3/4 grid grid-cols-1 text-left sm:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-12">
                        <div className="">
                            <h3 className="typo-subtitle mb-2"><FormattedMessage id="workshops.workshops" /></h3>
                            <ul className="typo-text-xl">
                                <li className="border-b-2 border-t-2 border-mt py-2"><Link to="/workshops/#basic" className="link-no-ul"><FormattedMessage id="workshops.workshops.basic" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/workshops/#kimchi" className="link-no-ul"><FormattedMessage id="workshops.workshops.kimchi" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/workshops/#kombucha" className="link-no-ul"><FormattedMessage id="workshops.workshops.kombucha" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/workshops/#custom" className="link-no-ul"><FormattedMessage id="workshops.workshops.custom" /></Link></li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="typo-subtitle mb-2"><FormattedMessage id="workshops.comingsoon" /></h3>
                            <ul className="typo-text-xl">
                                <li className="border-b-2 border-t-2 border-mt py-2"><Link to="/workshops/#comingsoon" className="link-no-ul"><FormattedMessage id="workshops.comingsoon.sauces" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/workshops/#comingsoon" className="link-no-ul"><FormattedMessage id="workshops.comingsoon.miso" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/workshops/#comingsoon" className="link-no-ul"><FormattedMessage id="workshops.comingsoon.ivanchai" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

            <div id="basic" className="section-bg py-24">

                <div className="container mx-auto px-8">
                    <div className="xl:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="workshops.basic.title" /></p>
                        <p className="typo-text-lg"><FormattedMessage id="workshops.basic.text" />
                        </p>

                    </div>
                </div>

                <div className="container mx-auto py-16 px-8">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopBasic04.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopBasic02.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopBasic01.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopBasic03.childImageSharp.fluid} /></div>
                    </div>
                </div>

                <div className="container mx-auto pb-16 md:pb-24 px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.basic.footnote1.title" /></h3>
                            <p><FormattedMessage id="workshops.basic.footnote1.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.basic.footnote2.title" /></h3>
                            <p><FormattedMessage id="workshops.basic.footnote2.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.basic.footnote3.title" /></h3>
                            <p><FormattedMessage id="workshops.basic.footnote3.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.basic.footnote4.title" /></h3>
                            <p><FormattedMessage id="workshops.basic.footnote4.text" /></p>
                        </div>

                    </div>

                </div>

                <div className="container mx-auto px-8">
                    <div className="p-8 sm:p-16 bg-mt text-mt-white rounded-4xl w-full">
                        <h3 className="typo-lg xl:2/3 mb-8"><FormattedMessage id="workshops.basic.book.title" /></h3>
                        <p className="typo-text-lg xl:2/3 mb-8">
                            <FormattedMessage id="workshops.basic.book.text" />
                        </p>
                        <a
                            href="https://forms.gle/BK2SVZPRz2jS14sv8"
                            className="btn btn-white"
                            aria-label={intl.formatMessage({ id: "workshops.basic.book.button" })}>
                            <FormattedMessage id="workshops.basic.book.button" />
                        </a>
                    </div>
                </div>

            </div>

            <div className="relative">
                <div className="photo-caption"><FormattedMessage id="workshops.photoCaption1" /></div>
                <Img className="" fluid={props.data.image3.childImageSharp.fluid} />
            </div>

            <div id="kimchi" className="section-bg py-24">

                <div className="container mx-auto px-8">
                    <div className="xl:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="workshops.kimchi.title" /></p>
                        <p className="typo-text-lg">
                            <FormattedMessage id="workshops.kimchi.text" />
                        </p>

                    </div>
                </div>

                <div className="container mx-auto py-16 px-8">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKimchi02.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKimchi01.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKimchi03.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKimchi04.childImageSharp.fluid} /></div>
                    </div>
                </div>

                <div className="container mx-auto pb-16 md:pb-24 px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kimchi.footnote1.title" /></h3>
                            <p><FormattedMessage id="workshops.kimchi.footnote1.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kimchi.footnote2.title" /></h3>
                            <p><FormattedMessage id="workshops.kimchi.footnote2.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kimchi.footnote3.title" /></h3>
                            <p><FormattedMessage id="workshops.kimchi.footnote3.text" /></p>
                        </div>

                    </div>

                </div>

                <div className="container mx-auto px-8">
                    <div className="p-8 sm:p-16 bg-mt text-mt-white rounded-4xl w-full">
                        <h3 className="typo-lg xl:2/3 mb-8"><FormattedMessage id="workshops.kimchi.book.title" /></h3>
                        <p className="typo-text-lg xl:2/3 mb-8">
                            <FormattedMessage id="workshops.kimchi.book.text" />
                        </p>
                        <a
                            href="https://forms.gle/BK2SVZPRz2jS14sv8"
                            className="btn btn-white"
                            aria-label={intl.formatMessage({ id: "workshops.kimchi.book.button" })}>
                            <FormattedMessage id="workshops.kimchi.book.button" />
                        </a>
                    </div>
                </div>



            </div>

            <div className="relative">
                <div className="photo-caption"><FormattedMessage id="workshops.photoCaption2" /></div>
                <Img className="" fluid={props.data.image4.childImageSharp.fluid} />
            </div>

            <div id="kombucha" className="section-bg py-24">

                <div className="container mx-auto px-8">
                    <div className="xl:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="workshops.kombucha.title" /></p>
                        <p className="typo-text-lg"><FormattedMessage id="workshops.kombucha.text" />
                        </p>
                    </div>
                </div>

                <div className="container mx-auto py-16 px-8">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKombucha02.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKombucha04.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKombucha03.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopKombucha01.childImageSharp.fluid} /></div>
                    </div>
                </div>

                <div className="container mx-auto pb-16 md:pb-24 px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kombucha.footnote1.title" /></h3>
                            <p><FormattedMessage id="workshops.kombucha.footnote1.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kombucha.footnote2.title" /></h3>
                            <p><FormattedMessage id="workshops.kombucha.footnote2.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kombucha.footnote3.title" /></h3>
                            <p><FormattedMessage id="workshops.kombucha.footnote3.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="workshops.kombucha.footnote4.title" /></h3>
                            <p><FormattedMessage id="workshops.kombucha.footnote4.text" /></p>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto px-8">
                    <div className="p-8 sm:p-16 bg-mt text-mt-white rounded-4xl w-full">
                        <h3 className="typo-lg xl:2/3 mb-4"><FormattedMessage id="workshops.kombucha.book.title" /></h3>
                        <p className="typo-text-lg xl:2/3 mb-8">
                            <FormattedMessage id="workshops.kombucha.book.text" />
                        </p>
                        <a
                            href="https://forms.gle/BK2SVZPRz2jS14sv8"
                            className="btn btn-white"
                            aria-label={intl.formatMessage({ id: "workshops.kombucha.book.button" })}>
                            <FormattedMessage id="workshops.kombucha.book.button" />
                        </a>
                    </div>
                </div>

            </div>

            <div className="relative">
                <div className="photo-caption"><FormattedMessage id="workshops.photoCaption3" /></div>
                <Img className="" fluid={props.data.image5.childImageSharp.fluid} />
            </div>

            <div id="custom" className="section-bg py-24">

                <div className="container mx-auto px-8">
                    <div className="lg:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="workshops.custom.title" /></p>
                        <p className="typo-text-lg mb-10"><FormattedMessage id="workshops.custom.text" />
                        </p>
                        <a
                            href="mailto:info@metsiktoit.ee"
                            className="btn btn-green"
                            aria-label={intl.formatMessage({ id: "workshops.custom.button" })}>
                            <FormattedMessage id="workshops.custom.button" />
                        </a>
                    </div>
                </div>

                <div className="container mx-auto py-16 px-8">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopCustom04.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopCustom02.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopCustom01.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgWorkshopCustom03.childImageSharp.fluid} /></div>
                    </div>
                </div>

            </div>

            <div id="comingsoon" className="container mx-auto sm:px-8">
                <div className="header-bg-04 bg-cover p-4 p-8 sm:p-16 lg:p-24 my-24 sm:rounded-4xl">

                    <div className="p-8 sm:p-16 bg-white rounded-4xl w-full shadow-lg">
                        <div className="">
                            <p className="typo-xl mb-8"><FormattedMessage id="workshops.new.title" /></p>
                            <p className="typo-text-lg mb-8"><FormattedMessage id="workshops.new.text" />
                            </p>
                            <a
                                href="https://forms.gle/WR4ptv4acdpGmUoV8" 
                                className="btn btn-black"
                                aria-label={intl.formatMessage({ id: "workshops.new.button" })}>
                                <FormattedMessage id="workshops.new.button" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-img-04 w-full">
                <div className="container mx-auto py-16 md:py-24 px-8">
                    <CrossroadsA />
                </div>
            </div>

        </Layout>
    )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageBasicStripe: file(relativePath: { eq: "metsiktoit_workshops_basic_stripe.png" }) {
      ...fluidImage
    }
    imageKimchiStripe: file(relativePath: { eq: "metsiktoit_workshops_kimchi_stripe.png" }) {
      ...fluidImage
    }
    imageKombuchaStripe: file(relativePath: { eq: "metsiktoit_workshops_kombucha_stripe.png" }) {
      ...fluidImage
    }
    imageCustomStripe: file(relativePath: { eq: "metsiktoit_workshops_custom_stripe.png" }) {
        ...fluidImage
    }
    image1: file(relativePath: { eq: "metsiktoit_img_02.png" }) {
        ...fluidImage
    }
    image3: file(relativePath: { eq: "metsiktoit_img_03.png" }) {
        ...fluidImage
    }
    image4: file(relativePath: { eq: "metsiktoit_img_04.png" }) {
        ...fluidImage
    }
    image5: file(relativePath: { eq: "metsiktoit_img_05.png" }) {
        ...fluidImage
    }
    image6: file(relativePath: { eq: "metsiktoit_img_06.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic01: file(relativePath: { eq: "metsiktoit_workshop_basic_01.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic02: file(relativePath: { eq: "metsiktoit_workshop_basic_02.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic03: file(relativePath: { eq: "metsiktoit_workshop_basic_03.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic04: file(relativePath: { eq: "metsiktoit_workshop_basic_04.png" }) {
        ...fluidImage
    }
    imgWorkshopKimchi01: file(relativePath: { eq: "metsiktoit_workshop_kimchi_01.png" }) {
        ...fluidImage
    }
    imgWorkshopKimchi02: file(relativePath: { eq: "metsiktoit_workshop_kimchi_02.png" }) {
        ...fluidImage
    }
    imgWorkshopKimchi03: file(relativePath: { eq: "metsiktoit_workshop_kimchi_03.png" }) {
        ...fluidImage
    }
    imgWorkshopKimchi04: file(relativePath: { eq: "metsiktoit_workshop_kimchi_04.png" }) {
        ...fluidImage
    }
    imgWorkshopKombucha01: file(relativePath: { eq: "metsiktoit_workshop_kombucha_01.png" }) {
        ...fluidImage
    }
    imgWorkshopKombucha02: file(relativePath: { eq: "metsiktoit_workshop_kombucha_02.png" }) {
        ...fluidImage
    }
    imgWorkshopKombucha03: file(relativePath: { eq: "metsiktoit_workshop_kombucha_03.png" }) {
        ...fluidImage
    }
    imgWorkshopKombucha04: file(relativePath: { eq: "metsiktoit_workshop_kombucha_04.png" }) {
        ...fluidImage
    }
    imgWorkshopCustom01: file(relativePath: { eq: "metsiktoit_workshop_custom_01.png" }) {
        ...fluidImage
    }
    imgWorkshopCustom02: file(relativePath: { eq: "metsiktoit_workshop_custom_02.png" }) {
        ...fluidImage
    }
    imgWorkshopCustom03: file(relativePath: { eq: "metsiktoit_workshop_custom_03.png" }) {
        ...fluidImage
    }
    imgWorkshopCustom04: file(relativePath: { eq: "metsiktoit_workshop_custom_04.png" }) {
        ...fluidImage
    }
    
  }
`